import React, { useRef, useState, useEffect } from 'react';

import Slider from 'react-slick';

import ClinicCard from '../../../components/jp-revamp/clinic-card';
import SelectInput from '../../../components/revamp/select-input';
import useI18n from '../../../hooks/use-i18n';

import '../../../styles/jp-revamp/clinic-section.scss';

const sliderSettings = {
  arrows: false,
  accessibility: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  autoplay: false,
  dots: true,
  responsive: [
    {
      breakpoint: 1786,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const ClinicSection = () => {
  const i18n = useI18n();

  const clinicTranslations = i18n.t('new_landing_page').clinics_section;

  const jpClinics = {
    [clinicTranslations.prefecture_1.prefecture_name]: [
      {
        clinicName: clinicTranslations.prefecture_1.clinics.clinic_1.clinic_name,
        address: clinicTranslations.prefecture_1.clinics.clinic_1.address,
        chiefDoctor: clinicTranslations.prefecture_1.clinics.clinic_1.chief_doctor,
        image: 'Akasaka-Sakura',
        locationLink: 'https://maps.app.goo.gl/oYb5juzYyiMBPhYK7'
      },
      {
        clinicName: clinicTranslations.prefecture_1.clinics.clinic_2.clinic_name,
        address: clinicTranslations.prefecture_1.clinics.clinic_2.address,
        chiefDoctor: clinicTranslations.prefecture_1.clinics.clinic_2.chief_doctor,
        image: 'Ojima-Ekimae',
        locationLink: 'https://maps.app.goo.gl/QtpGCwmN1KYW2ktP8'
      },
      {
        clinicName: clinicTranslations.prefecture_1.clinics.clinic_3.clinic_name,
        address: clinicTranslations.prefecture_1.clinics.clinic_3.address,
        chiefDoctor: clinicTranslations.prefecture_1.clinics.clinic_3.chief_doctor,
        image: 'Sakurai-Orthodontics',
        locationLink: 'https://www.google.com/maps/place/%E3%82%B9%E3%83%9E%E3%82%A4%E3%83%AB%EF%BC%8B%E3%81%95%E3%81%8F%E3%82%89%E3%81%84%E6%AD%AF%E5%88%97%E7%9F%AF%E6%AD%A3%E6%AD%AF%E7%A7%91%E4%BA%8C%E5%AD%90%E7%8E%89%E5%B7%9D/@35.6134835,139.6259806,17z/data=!3m2!4b1!5s0x6018f415ca5945f7:0xa05004af17e326bb!4m6!3m5!1s0x6018f415cbc6f597:0xa98cf7b4edc42580!8m2!3d35.6134835!4d139.6259806!16s%2Fg%2F1pp2tvh8x?entry=ttu'
      }
    ],
    [clinicTranslations.prefecture_2.prefecture_name]: [
      {
        clinicName: clinicTranslations.prefecture_2.clinics.clinic_1.clinic_name,
        address: clinicTranslations.prefecture_2.clinics.clinic_1.address,
        chiefDoctor: clinicTranslations.prefecture_2.clinics.clinic_1.chief_doctor,
        image: 'Midorino-Terrace',
        locationLink: 'https://maps.app.goo.gl/eFJDzi8j4NkVeHZV7'
      }
    ],
    [clinicTranslations.prefecture_3.prefecture_name]: [
      {
        clinicName: clinicTranslations.prefecture_3.clinics.clinic_1.clinic_name,
        address: clinicTranslations.prefecture_3.clinics.clinic_1.address,
        chiefDoctor: clinicTranslations.prefecture_3.clinics.clinic_1.chief_doctor,
        image: 'Tanimachi-Rokuchome-Sichigo',
        locationLink: 'https://www.google.com/maps/place/%E3%83%9B%E3%83%AF%E3%82%A4%E3%83%88%E3%83%8E%E3%82%A2%E6%AD%AF%E7%A7%91/@34.6939889,135.4835301,15z/data=!4m2!3m1!1s0x0:0xd07df03860ae3b8b?sa=X&ved=2ahUKEwioqITh78yEAxUQn68BHaooCC4Q_BJ6BAhcEAA'
      }
    ],
    [clinicTranslations.prefecture_4.prefecture_name]: [
      {
        clinicName: clinicTranslations.prefecture_4.clinics.clinic_1.clinic_name,
        address: clinicTranslations.prefecture_4.clinics.clinic_1.address,
        chiefDoctor: clinicTranslations.prefecture_4.clinics.clinic_1.chief_doctor,
        image: 'Takaraduka-Asahi',
        locationLink: 'https://maps.app.goo.gl/rRqunRo1hB6i83kp7'
      }
    ],
    [clinicTranslations.prefecture_5.prefecture_name]: [
      {
        clinicName: clinicTranslations.prefecture_5.clinics.clinic_1.clinic_name,
        address: clinicTranslations.prefecture_5.clinics.clinic_1.address,
        chiefDoctor: clinicTranslations.prefecture_5.clinics.clinic_1.chief_doctor,
        image: 'Nagoya',
        locationLink: 'https://maps.app.goo.gl/6RM4Hfd9JPpJTNUYA'
      }
    ],
    [clinicTranslations.prefecture_6.prefecture_name]: [
      {
        clinicName: clinicTranslations.prefecture_6.clinics.clinic_1.clinic_name,
        address: clinicTranslations.prefecture_6.clinics.clinic_1.address,
        chiefDoctor: clinicTranslations.prefecture_6.clinics.clinic_1.chief_doctor,
        image: 'Scone',
        locationLink: 'https://maps.app.goo.gl/yzxdKYb8nJUxSzNk6'
      }
    ],
    [clinicTranslations.prefecture_7.prefecture_name]: [
      {
        clinicName: clinicTranslations.prefecture_7.clinics.clinic_1.clinic_name,
        address: clinicTranslations.prefecture_7.clinics.clinic_1.address,
        chiefDoctor: clinicTranslations.prefecture_7.clinics.clinic_1.chief_doctor,
        image: 'Whiteberry-Konan',
        locationLink: 'https://maps.app.goo.gl/bEvPUY4ufhfdTXfx6'
      }
    ],
    [clinicTranslations.prefecture_8.prefecture_name]: [
      {
        clinicName: clinicTranslations.prefecture_8.clinics.clinic_1.clinic_name,
        address: clinicTranslations.prefecture_8.clinics.clinic_1.address,
        chiefDoctor: clinicTranslations.prefecture_8.clinics.clinic_1.chief_doctor,
        image: 'Tenjin-Oral-Care',
        locationLink: 'https://maps.app.goo.gl/T7feZGFtvXjNXvVd8'
      }
    ]
  };

  const clinicLocations = Object.keys(jpClinics);

  const sliderRef = useRef(null);

  const [activeLocation, setActiveLocation] = useState(clinicLocations[0]);
  const [maxHeight, setMaxHeight] = useState(0);

  const locationIndex = clinicLocations.indexOf(activeLocation);

  const activeClinics = jpClinics[activeLocation];

  const setCardHeight = () => {
    let contentHeight = 0;
    const cards = document.querySelectorAll('.clinic-content');
    cards.forEach((content) => {
      contentHeight = Math.max(contentHeight, content.offsetHeight);
    });
    setMaxHeight(contentHeight);
  };

  const onResize = () => {
    setTimeout(() => {
      setMaxHeight(0);
      if (window.innerWidth < 768) { setCardHeight(); }
    }, 10);
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      setMaxHeight(0);
    };
  }, [activeLocation]);

  return (
    <section className="clinic-section" id="clinic-selection">
      <h2
        className="main-heading"
        data-key="new_landing_page::clinics_section::heading"
        data-lokalise=""
      >
        {clinicTranslations.heading}
      </h2>
      <h3
        className="sub-heading"
        data-key="new_landing_page::clinics_section::sub_heading"
        data-lokalise=""
      >
        {clinicTranslations.sub_heading}
      </h3>
      <SelectInput
        selectedItem={activeLocation}
        dropDownOptions={clinicLocations}
        handleOptionClick={setActiveLocation}
      />
      <div className="location-tabs">
        {clinicLocations.map((location, index) => (
          <div
            className={`location-tab ${location === activeLocation ? 'active' : ''}`}
            role="presentation"
            key={location}
            onClick={() => setActiveLocation(location)}
            data-key={`new_landing_page::clinics_section::prefecture_${index}_prefecture_name`}
            data-lokalise=""
          >
            {location}
          </div>
        ))}
      </div>
      <div className="location-slider-wrapper">
        <Slider {...sliderSettings} ref={sliderRef} className="slider-cards-wrapper" key={activeLocation}>
          {activeClinics.map((clinic, index) => (
            <ClinicCard
              key={clinic.clinicName}
              clinicData={clinic}
              clinics={activeClinics}
              sliderRef={sliderRef}
              clinicTranslations={clinicTranslations}
              clinicNo={index}
              locationNo={locationIndex}
              maxHeight={maxHeight}
            />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default ClinicSection;
