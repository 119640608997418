/* eslint-disable react/no-multi-comp */
import React from 'react';

// import dynamic from 'next/dynamic';
import Head from 'next/head';

import Layout from '../../components/layout/layout';
// import RestrictedPageLoader from '../../components/loader/restrictedpage';
import HomePageRevamp from '../../containers/homePage/homePage';
import JpHomePage from '../../containers/homePage/jpHomePage';
import useI18n from '../../hooks/use-i18n';
import { contentLanguageMap, languages } from '../../language.config';
import getTranslationKeys, { PAGEID } from '../../utils/i18nRosseta';
import { customEventParam } from '../../utils/utils';

// const HomePageRevamp = dynamic(() => import('../../containers/homePage/homePage'), {
//   loading: () => <RestrictedPageLoader />, ssr: false
// });

// const JpHomePage = dynamic(() => import('../../containers/homePage/jpHomePage'), {
//   loading: () => <RestrictedPageLoader />, ssr: false
// });

const HomePage = () => {
  const i18n = useI18n();
  const COUNTRY_LANGUAGE = i18n.activeLocale;
  const codes = COUNTRY_LANGUAGE.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  const pageMetaData = i18n.t('metadata').homePage;
  return (
    <Layout
      title={pageMetaData.title}
      insiderPage="Home"
      page="Home"
      keywords={pageMetaData.keywords}
      analyticData={{ name: 'Home', properties: {} }}
      description={pageMetaData.description}
      pageUrl=""
      pageEnabledMarkets={[]}
      customEvent={customEventParam[country] && customEventParam[country].type1}
    >
      <Head>
        <link rel="stylesheet" href="/vendor/slick/slick.min.css" />
        <link rel="stylesheet" href="/vendor/slick/slick-theme.min.css" defer={true} />
      </Head>
      { country !== 'jp' ? <HomePageRevamp /> : <JpHomePage />}
    </Layout>
  );
};

export default HomePage;

export async function getStaticProps({ params }) {
  const lngDict = {};
  const pageId = PAGEID.HOME;
  const keys = getTranslationKeys(pageId);
  const { default: lngDictAll = {} } = await import(
    `../../locales/${contentLanguageMap[params.lng]}.json`
  );
  keys.forEach((data) => {
    lngDict[data] = lngDictAll[data];
  });
  return {
    props: { lng: params.lng, lngDict, pageId }
  };
}

export async function getStaticPaths() {
  return {
    paths: languages.map((l) => ({ params: { lng: l } })),
    fallback: false
  };
}
