import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import useI18n from '../../hooks/use-i18n';

import '../../styles/jp-revamp/review-card.scss';

const ReviewsCard = (props) => {
  const { reviewContent,
    lokaliseKey,
    activeSlideIndex,
    slideIndex,
    ratingCount = 5,
    totalRatingCount = 5,
    contentSliceCharacterCount = 152 } = props;

  const i18n = useI18n();

  const reviewSection = i18n.t('new_landing_page.reviews_section');

  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isCharMinCount, setIsMinCharCount] = useState();

  const expandButton = useRef(null);

  const filledStars = Math.min(ratingCount, totalRatingCount);
  const unfilledStars = totalRatingCount - filledStars;

  const filledStar = <img src="/img/jp-home-page/icons/star-filled.svg" alt="Filled Star" data-gumlet="false" />;
  const unfilledStar = <img src="/img/jp-home-page/icons/unfilled-star.svg" alt="Unfilled Star" data-gumlet="false" />;

  const filledStarElements = Array(filledStars).fill(filledStar);
  const unfilledStarElements = Array(unfilledStars).fill(unfilledStar);

  const reviewStars = [...filledStarElements, ...unfilledStarElements];

  function screenWidth() {
    setIsMobile(window.innerWidth < 1025.9);
  }

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (activeSlideIndex !== slideIndex) { setIsExpanded(false); }
  }, [activeSlideIndex]);

  useEffect(() => {
    if (reviewContent) setIsMinCharCount(reviewContent.length > 150);
    if (typeof window !== 'undefined') screenWidth();
  }, []);

  return (
    <div className="review-card-wrapper">
      <div className="review-star-section">
        {
            reviewStars.map((star, index) => (
              <span key={`${index + 1} key`}>{star}</span>
            ))
        }
      </div>
      <div className="review-content-wrapper">
        <div className="review-content" id="review-content">
          <p
            id="content"
            data-lokalise=""
            data-key={lokaliseKey}
          >
            {!isExpanded
            && isMobile
            && isCharMinCount
              ? `${reviewContent.slice(0, contentSliceCharacterCount)} ... `
              : reviewContent}
            {!isExpanded
            && isCharMinCount
            && (
              <span
                role="presentation"
                ref={expandButton}
                onClick={toggleReadMore}
                data-lokalise=""
                data-key="new_landing_page::reviews_section::read_more"
              >
                {reviewSection.read_more}
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewsCard;

ReviewsCard.propTypes = {
  reviewContent: PropTypes.string,
  ratingCount: PropTypes.number,
  totalRatingCount: PropTypes.number,
  contentSliceCharacterCount: PropTypes.number,
  lokaliseKey: PropTypes.string,
  activeSlideIndex: PropTypes.number,
  slideIndex: PropTypes.number
};

ReviewsCard.defaultProps = {
  reviewContent: '',
  ratingCount: 5,
  totalRatingCount: 5,
  contentSliceCharacterCount: 152,
  lokaliseKey: '',
  activeSlideIndex: 0,
  slideIndex: 0
};
