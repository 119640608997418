import React, { useEffect, useState } from 'react';

import Slider from 'react-slick';

import Button from '../../../components/jp-revamp/button';
import ReviewsCard from '../../../components/jp-revamp/reviews-card';
import useI18n from '../../../hooks/use-i18n';
import { reviewLinksJp } from '../../../links.config';

import '../../../styles/jp-revamp/review-slider.scss';

const ReviewsSlider = () => {
  const i18n = useI18n();
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const reviewSection = i18n.t('new_landing_page.reviews_section');

  const reviewSliderJpHomePage = {
    accessibility: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    touchThreshold: 10,
    beforeChange: (current, next) => {
      setActiveSlideIndex(next);
    },
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          swipe: true,
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          swipe: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 576,
        settings: {
          swipe: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };

  const reviewData = [
    { reviewContent: 'review_one',
      ratingCount: 5,
      contentSliceCharacterCount: 151 },
    { reviewContent: 'review_two',
      ratingCount: 4,
      contentSliceCharacterCount: 156 },
    { reviewContent: 'review_three',
      ratingCount: 5,
      contentSliceCharacterCount: 150 },
    { reviewContent: 'review_four',
      ratingCount: 5,
      contentSliceCharacterCount: 154 }
  ];

  const getMaxHeight = () => {
    let maxHeight = 0;
    const cardTexts = document.querySelectorAll('.review-card-wrapper');
    cardTexts.forEach((text) => {
      // eslint-disable-next-line no-param-reassign
      text.style.minHeight = 0;
    });
    cardTexts.forEach((text) => {
      if (text.offsetHeight > maxHeight) {
        maxHeight = text.offsetHeight;
      }
    });
    cardTexts.forEach((text) => {
      // eslint-disable-next-line no-param-reassign
      text.style.minHeight = `${maxHeight}px`;
    });
  };

  useEffect(() => {
    setTimeout(() => {
      getMaxHeight();
    }, 200);
    window.addEventListener('resize', getMaxHeight);
    return () => {
      window.removeEventListener('resize', getMaxHeight);
    };
  }, []);

  return (
    <section>
      <div className="review-slider">
        <h2
          className="section-heading"
          data-lokalise=""
          data-key="new_landing_page::reviews_section::review_heading"
        >
          {reviewSection.review_heading}
        </h2>
        <Slider
          {...reviewSliderJpHomePage}
        >
          {reviewData.map((reviewItem, index) => (
            <ReviewsCard
              contentSliceCharacterCount={reviewItem.contentSliceCharacterCount}
              key={reviewItem.reviewContent}
              lokaliseKey={`new_landing_page::reviews_section::review_card_details::${reviewItem.reviewContent}`}
              ratingCount={reviewItem.ratingCount}
              reviewContent={reviewSection.review_card_details[reviewItem.reviewContent]}
              slideIndex={index}
              activeSlideIndex={activeSlideIndex}
            />))}
        </Slider>
        <Button externalLink={true} href={reviewLinksJp.google} target="_blank">
          <div className="review-rating">
            <div className="review-provider">
              <img src="/img/jp-home-page/icons/google.svg" alt="google-review" data-gumlet="false" />
              <p
                data-lokalise=""
                data-key="new_landing_page::reviews_section::review_provider"
              >
                {reviewSection.review_provider}
              </p>
            </div>
            <div className="star-ratings">
              <p
                data-lokalise=""
                data-key="new_landing_page::reviews_section::review_details::rating_count"
              >
                {reviewSection.review_details.rating_count}
              </p>
              <img src="/img/updated/homepage/rating_icons.png" data-gumlet="false" alt="Star-rating" />
            </div>
          </div>
        </Button>
      </div>
    </section>
  );
};

export default ReviewsSlider;
