/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';

import Slider from 'react-slick';

import useI18n from '../../../hooks/use-i18n';
import { animatedBannerSlider } from '../../../utils/slider.config';

import '../../../styles/jp-revamp/animated-banner.scss';

const AnimatedToothBanner = () => {
  const i18n = useI18n();
  const codes = i18n.activeLocale.split('-');
  const lang = codes[1] || 'en';

  const animatedBannerSection = i18n.t('new_landing_page.animated_banner_section');

  const toothConditions = ['crowding', 'rotation', 'gaps', 'crossbite', 'overbite', 'underbite'];

  return (
    <section className="aligner-animated-section" id="treatment-technology">
      <div className="animated-container">
        <div className="left-col">
          <img src="/img/jp-home-page/animated-banner-slider/invisible-aligner-treatment.gif" alt="animated tooth" data-gumlet="false" />
        </div>
        <div className="right-col">
          <h1 className="main-heading">
            <span
              className="highlight-text"
              data-lokalise=""
              data-key="new_landing_page::animated_banner_section::animated_heading_1"
            >
              {animatedBannerSection.animated_heading_1}
            </span>
                  &nbsp;
            <span
              className={`${lang === 'en' || lang === 'vi' ? 'highlight-text' : ''} ${lang !== 'vi' ? 'text-align-others' : ''}`}
              data-lokalise=""
              data-key="new_landing_page::animated_banner_section::animated_heading_2"
            >
              {animatedBannerSection.animated_heading_2}
            </span>
          </h1>
          <h1
            className="sub-heading"
            data-lokalise=""
            data-key="new_landing_page::animated_banner_section::animated_sub_heading"
            dangerouslySetInnerHTML={{ __html: animatedBannerSection.animated_sub_heading }}
          />
        </div>
      </div>
      <div className="animated-banner-content">
        <h2
          className="content-heading"
          data-lokalise=""
          data-key="new_landing_page::animated_banner_section::animated_sub_section_heading"
        >
          {animatedBannerSection.animated_sub_section_heading}
        </h2>
        <div className="conditions-slider-wrapper">
          <Slider {...animatedBannerSlider} className="conditions-slider">
            {toothConditions.map((item) => (
              <div className="conditions-slide-wrapper" key={item}>
                <div className="slide-image-wrapper">
                  <img src={`/img/jp-home-page/animated-banner-slider/${item}.svg`} alt={animatedBannerSection.teeth_conditions[item]} data-gumlet="false" />
                </div>
                <h3
                  data-lokalise=""
                  data-key={`new_landing_page::animated_banner_section::teeth_conditions::${item}`}
                >
                  {animatedBannerSection.teeth_conditions[item]}
                </h3>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="iso-logo-container">
        <img src="/img/bsi_white.svg" alt="ISO Logo" data-gumlet="false" />
      </div>
    </section>
  );
};

export default AnimatedToothBanner;
