/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { promoBannerClicked, promoBannerDiscoverClicked } from '../../helpers/segment';
import useI18n from '../../hooks/use-i18n';
import { countryMap } from '../../language.config';
import { gumletAWSClean } from '../../utils/utils';

const MainBanner = (props) => {
  const { bannerDesktop, bannerMobile, utmParams, isLoaded } = props;

  const i18n = useI18n();
  const COUNTRY_LANGUAGE = i18n.activeLocale;
  const codes = COUNTRY_LANGUAGE.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 672);
  }, []);

  return (
    <>
      {isLoaded ? (
        <section
          data-gumlet="true"
          className={`homenew-layout-jp__banner mobile-${bannerMobile.textTheme} desktop-${bannerDesktop.textTheme}`}
          // data-bg={isMobile ? gumletAWSClean(bannerMobile?.bannerUrl) : gumletAWSClean(bannerDesktop?.bannerUrl)}
          style={{ backgroundImage: `url(${isMobile ? bannerMobile?.bannerUrl : bannerDesktop?.bannerUrl})` }}
        >
          <div>
            <h1>
              {isMobile ? bannerMobile.header : bannerDesktop.header}
            </h1>
            <p
              dangerouslySetInnerHTML={{ __html: isMobile
                ? bannerMobile.body : bannerDesktop.body }}
            />
            <div className="homenew-buttonbox">
              {bannerDesktop.hyperLink1 && (
              <a
                href={`${isMobile ? bannerMobile.hyperLink1
                  || bannerDesktop.hyperLink1
                  : bannerDesktop.hyperLink1}${bannerDesktop.hyperLink1.includes('obt')
                  ? utmParams : ''}`}
                className="web-btn jp-banner-btn"
                onClick={() => { promoBannerClicked(countryMap[country], bannerDesktop.cta1, bannerDesktop.cta1); }}
              >
                {isMobile ? bannerMobile.cta1 || bannerDesktop.cta1 : bannerDesktop.cta1}
              </a>
              )}
              {bannerDesktop.hyperLink2 && (
              <a
                className="learn-more"
                href={`${isMobile ? bannerMobile.hyperLink2
                  || bannerDesktop.hyperLink2
                  : bannerDesktop.hyperLink2}${bannerDesktop.hyperLink2.includes('obt')
                  ? utmParams : ''}`}
                onClick={() => {
                  promoBannerDiscoverClicked(countryMap[country], bannerDesktop.cta2, bannerDesktop.cta2);
                }}
              >
                {isMobile ? bannerMobile.cta2 || bannerDesktop.cta2 : bannerDesktop.cta2}
              </a>
              )}
            </div>
          </div>
        </section>) : (
          <div className="banner-skeleton-loading">
            <div className="banner-content" />
            <div className="banner-sub-content" />
          </div>
      )}
    </>
  );
};

export default MainBanner;

MainBanner.propTypes = {
  bannerDesktop: PropTypes.shape().isRequired,
  bannerMobile: PropTypes.shape().isRequired,
  utmParams: PropTypes.string.isRequired,
  isLoaded: PropTypes.bool.isRequired
};
