import React, { memo } from 'react';

import PropTypes from 'prop-types';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import '../../styles/jp-revamp/before-after-content-image.scss';

const BeforeAfterContentImage = (props) => {
  const { beforeImageUrl,
    afterImageUrl,
    position,
    onlyHandleDraggable,
    setIsCompareSliderActive,
    boundsPadding } = props;

  return (
    <div className="before-after-image-wrapper">
      <ReactCompareSlider
        itemOne={<ReactCompareSliderImage
          src={beforeImageUrl}
          data-gumlet="false"
          alt="Image one"
        />}
        itemTwo={<ReactCompareSliderImage
          src={afterImageUrl}
          data-gumlet="false"
          alt="Image two"
        />}
        position={position}
        onlyHandleDraggable={onlyHandleDraggable}
        boundsPadding={boundsPadding}
        onPositionChange={() => {
          setIsCompareSliderActive(false);
          setTimeout(() => {
            setIsCompareSliderActive(true);
          }, 200);
        }}
      />

    </div>
  );
};

export default memo(BeforeAfterContentImage);

BeforeAfterContentImage.propTypes = {
  beforeImageUrl: PropTypes.string,
  afterImageUrl: PropTypes.string,
  position: PropTypes.number,
  onlyHandleDraggable: PropTypes.bool,
  setIsCompareSliderActive: PropTypes.func,
  boundsPadding: PropTypes.string
};

BeforeAfterContentImage.defaultProps = {
  beforeImageUrl: '',
  afterImageUrl: '',
  position: '',
  onlyHandleDraggable: false,
  setIsCompareSliderActive: () => {},
  boundsPadding: ''
};
