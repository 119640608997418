import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '../../../components/jp-revamp/button';
import useI18n from '../../../hooks/use-i18n';
import { obt3EnabledMarkets } from '../../../links.config';

import '../../../styles/jp-revamp/cta-banner-two.scss';

const CtaBanner2 = ({ utmParams }) => {
  const i18n = useI18n();
  const COUNTRY_LANGUAGE = i18n.activeLocale;
  const codes = COUNTRY_LANGUAGE.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  const lang = codes[1] || 'en';

  const ctaBannerSection = i18n.t('new_landing_page.cta_banner_section');
  const contact = i18n.t('contact');

  const [isMobile, setIsMobile] = useState(false);

  const ctaBannerContentData = [
    { content: ctaBannerSection.cta_content.cta_content_one, key: 'cta_content_one' },
    { content: ctaBannerSection.cta_content.cta_content_two, key: 'cta_content_two' },
    { content: ctaBannerSection.cta_content.cta_content_three, key: 'cta_content_three' }
  ];

  useEffect(() => {
    setIsMobile(window.innerWidth < 1024);
  }, []);

  return (
    <section className="cta-banner-section">
      <div className="cta-banner-two-wrapper">
        <div className="banner-section">
          <img
            data-src={`/img/jp-home-page/banner/cta-banner-${isMobile ? 'mobile' : 'desktop'}.png`}
            alt={ctaBannerSection.cta_heading}
          />
        </div>
        <div className="cta-banner-two-content">
          <h2
            data-lokalise=""
            data-key="new_landing_page::cta_banner_section::cta_heading"
          >
            {ctaBannerSection.cta_heading}
          </h2>
          <ul className="content-list">
            {ctaBannerContentData.map((data) => (
              <li
                data-lokalise=""
                data-key={`new_landing_page::cta_banner_section::cta_content::${data.key}`}
                key={data.key}
              >
                <img src="/img/jp-home-page/icons/list.svg" alt="list" data-gumlet="false" />
                <p>
                  {data.content}
                </p>
              </li>
            ))}
          </ul>
          <div className="cta-links">
            <Button
              href={`${obt3EnabledMarkets.includes(country) ? process.env.NEXT_PUBLIC_OBT3_LINK
                : process.env.NEXT_PUBLIC_OBT_LINK}${country}-${lang}/hello-screen/${utmParams}`}
              className="button-one"
              externalLink={true}
              as=""
              loakliseKey="new_landing_page::cta_banner_section::free_assessment_button"
            >
              <>{ctaBannerSection.free_assessment_button}</>
            </Button>
            <Button href={contact[country].line_link} className="button-two" externalLink={true} target="_blank">
              <>
                <img src="/img/jp-home-page/icons/line.svg" alt="line" data-gumlet="false" />
                <span
                  data-lokalise=""
                  data-key="new_landing_page::cta_banner_section::social_media_line"
                >
                  {ctaBannerSection.social_media_line}
                </span>
              </>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaBanner2;

CtaBanner2.propTypes = {
  utmParams: PropTypes.string
};

CtaBanner2.defaultProps = {
  utmParams: ''
};
