import React from 'react';

import Link from 'next/link';
import PropTypes from 'prop-types';

const Button = (props) => {
  const { children, href, className, externalLink, onClickFunction, as, lokaliseKey, target } = props;

  if (!externalLink) {
    return (
      <Link href={href} as={as}>
        <a
          role="presentation"
          className={className}
          onClick={onClickFunction}
          data-lokalise=""
          data-key={lokaliseKey}
        >
          {children}
        </a>
      </Link>);
  }
  return (
    <a
      className={className}
      href={href}
      referrerPolicy="no-referrer"
      rel="noreferrer"
      onClick={onClickFunction}
      data-lokalise=""
      data-key={lokaliseKey}
      target={target}
    >
      {children}
    </a>

  );
};

export default Button;

Button.propTypes = {
  children: PropTypes.element,
  href: PropTypes.string,
  className: PropTypes.string,
  externalLink: PropTypes.bool,
  onClickFunction: PropTypes.func,
  as: PropTypes.string,
  lokaliseKey: PropTypes.string,
  target: PropTypes.string
};

Button.defaultProps = {
  href: '',
  className: '',
  externalLink: false,
  onClickFunction: () => {},
  as: '',
  lokaliseKey: '',
  target: '_self',
  children: <></>
};
