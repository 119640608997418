import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import '../../styles/revamp/select-input.scss';

const SelectInput = (props) => {
  const { selectedItem, dropDownOptions, handleOptionClick } = props;

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const dropDownRef = useRef(null);

  const handleSelectClick = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const onOptionClick = (option) => {
    handleOptionClick(option);
    setIsSelectOpen(false);
  };

  const handleClick = (e) => {
    if (dropDownRef.current && dropDownRef.current.contains(e.target)) {
      return;
    }
    setIsSelectOpen(false);
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClick);
    return () => {
      window.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div className="location-select-wrapper" ref={dropDownRef}>
      <div className="location-select" onClick={handleSelectClick} role="presentation">
        <span>{selectedItem}</span>
        <img
          src="/img/chervron-down.svg"
          className={isSelectOpen ? 'rotate' : ''}
          alt="select-arrow"
          data-gumlet="false"
        />
      </div>
      <div className={`location-options ${isSelectOpen ? 'open' : ''}`}>
        {dropDownOptions.map((option) => (
          <p
            key={option}
            className={`location-option ${selectedItem === option ? 'selected' : ''}`}
            onClick={() => onOptionClick(option)}
            role="presentation"
          >
            {option}
          </p>
        ))}
      </div>
    </div>
  );
};

export default SelectInput;

SelectInput.propTypes = {
  selectedItem: PropTypes.string.isRequired,
  dropDownOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleOptionClick: PropTypes.func
};

SelectInput.defaultProps = {
  handleOptionClick: () => {}
};
