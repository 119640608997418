import enc from 'crypto-js';
import sha256 from 'crypto-js/sha256';

export const paymentSegmentIdentify = (email, data) => {
  const userId = sha256(email).toString(enc.Hex);
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.identify(userId, {
      ...data
    });
  }
};

export const paymentPageViewEvent = (page, data) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.page(page, {
      ...data,
      payment_flow: localStorage.getItem('paymentType') || 'Aligner Payments'
    });
  }
};

export const trackPaymentEvent = (title, data = {}) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track(title, {
      ...data,
      payment_flow: localStorage.getItem('paymentType') || 'Aligner Payments'
    });
  }
};
