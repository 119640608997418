import React from 'react';

import PropTypes from 'prop-types';

import '../../styles/slider-card.scss';

const SliderCard = (props) => {
  const { imageUrl, contentHeading, content, lokaliseKey } = props;

  return (
    <div className="slider-card-wrapper">
      <div className="slider-card-content">
        <h3
          className="slider-card-heading"
          data-lokalise=""
          data-key={`new_landing_page::detailed_section::details_card::${lokaliseKey}::heading`}
        >
          {contentHeading}
        </h3>
        <p
          className="slider-card-subcontent"
          data-lokalise=""
          data-key={`new_landing_page::detailed_section::details_card::${lokaliseKey}::subsection`}
        >
          {content}
        </p>
      </div>
      <div
        className="slider-card-image"
        data-bg={imageUrl}
        data-gmlazy="false"
      />
    </div>
  );
};

export default SliderCard;

SliderCard.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  contentHeading: PropTypes.string.isRequired,
  lokaliseKey: PropTypes.string
};

SliderCard.defaultProps = {
  lokaliseKey: ''
};
