import React from 'react';

import Slider from 'react-slick';

import SmileJourneyCard from '../../../components/revamp/smile-journey-card';
import useI18n from '../../../hooks/use-i18n';
import '../../../styles/revamp/smile-journey.scss';

const smileJourneySliderSettings = {
  arrows: false,
  accessibility: true,
  infinite: false,
  speed: 700,
  slidesToScroll: 1,
  dots: false,
  swipe: false,
  slidesToShow: 4,
  touchThreshold: 10,
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        swipe: true,
        slidesToShow: 3,
        dots: true
      }
    },
    {
      breakpoint: 1400,
      settings: {
        swipe: true,
        slidesToShow: 3,
        dots: true
      }
    },
    {
      breakpoint: 1260,
      settings: {
        swipe: true,
        slidesToShow: 2.5,
        slidesToScroll: 2,
        dots: true
      }
    },
    {
      breakpoint: 1055,
      settings: {
        swipe: true,
        slidesToShow: 2.25,
        slidesToScroll: 2,
        dots: true
      }
    },
    {
      breakpoint: 928,
      settings: {
        swipe: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true
      }
    },
    {
      breakpoint: 826,
      settings: {
        swipe: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }
  ]
};

const SmileJourney = () => {
  const i18n = useI18n();

  const smileJourneyTranslations = i18n.t('new_landing_page').smile_journey_section;

  const cardTranslations = smileJourneyTranslations.smile_journey_cards;

  const smileJourneyCards = {
    step1: {
      title: cardTranslations.step1_card.title,
      content: cardTranslations.step1_card.content
    },
    step2: {
      title: cardTranslations.step2_card.title,
      content: cardTranslations.step2_card.content
    },
    step3: {
      title: cardTranslations.step3_card.title,
      content: cardTranslations.step3_card.content
    },
    step4: {
      title: cardTranslations.step4_card.title,
      content: cardTranslations.step4_card.content
    }
  };

  return (
    <section className="smile-journey-section" id="smile-journey">
      <h2
        className="smile-journey-title"
        data-key="new_landing_page::smile_journey_section::heading"
        data-lokalise=""
      >
        {smileJourneyTranslations.heading}
      </h2>
      <div className="cards-wrapper">
        <Slider {...smileJourneySliderSettings} className="smile-journey-cards">
          {Object.entries(smileJourneyCards).map(
            ([key, value]) => (
              <React.Fragment key={`smile-journey-${key}`}>
                <SmileJourneyCard
                  step={key}
                  cardDetails={value}
                />
              </React.Fragment>
            )
          )}
        </Slider>
      </div>
    </section>
  );
};

export default SmileJourney;
