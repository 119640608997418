import React from 'react';

import PropTypes from 'prop-types';

import '../../styles/jp-revamp/clinic-card.scss';

const ClinicCard = (props) => {
  const { clinicData, clinicTranslations, clinicNo, locationNo, maxHeight } = props;

  return (
    <div className="clinic-card">
      <div className="clinic-img-container">
        <img
          src={`/img/jp-home-page/dental-clinics/${clinicData.image}.webp`}
          data-gmlazy="false"
          data-gumlet="false"
          // data-src={`/img/jp-home-page/dental-clinics/${clinicData.image}.webp`}
          alt={clinicData.clinicName}
        />
      </div>
      <div className="clinic-details">
        <div className="clinic-content" style={{ minHeight: maxHeight }}>
          <h2
            data-key={`new_landing_page::clinics_section::prefecture_${locationNo
          + 1}::clinics::clinic_${clinicNo + 1}::clinic_name`}
            data-lokalise=""
          >
            {clinicData.clinicName}
          </h2>
          <div className="clinic-data">
            <p
              data-key="new_landing_page::clinics_section::address_label"
              data-lokalise=""
            >
              {clinicTranslations.address_label}
            </p>
            <p
              data-key={`new_landing_page::clinics_section::prefecture_${locationNo
              + 1}::clinics::clinic_${clinicNo + 1}::address`}
              data-lokalise=""
            >
              {clinicData.address}
            </p>
          </div>
          <div className="clinic-data">
            <p
              data-key="new_landing_page::clinics_section::doctor_label"
              data-lokalise=""
            >
              {clinicTranslations.doctor_label}
            </p>
            <p
              data-key={`new_landing_page::clinics_section::prefecture_${locationNo
              + 1}::clinics::clinic_${clinicNo + 1}::chief_doctor`}
              data-lokalise=""
            >
              {clinicData.chiefDoctor}
            </p>
          </div>
        </div>
        <a
          href={clinicData.locationLink}
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
          data-key="new_landing_page::clinics_section::location_link_button"
          data-lokalise=""
        >
          {clinicTranslations.location_link_button}
        </a>
      </div>
    </div>
  );
};

export default ClinicCard;

ClinicCard.propTypes = {
  clinicData: PropTypes.shape({
    clinicName: PropTypes.string,
    address: PropTypes.string,
    chiefDoctor: PropTypes.string,
    image: PropTypes.string,
    locationLink: PropTypes.string
  }).isRequired,
  clinicTranslations: PropTypes.shape(),
  clinicNo: PropTypes.number,
  locationNo: PropTypes.number,
  maxHeight: PropTypes.number
};

ClinicCard.defaultProps = {
  clinicTranslations: {},
  clinicNo: 0,
  locationNo: 0,
  maxHeight: 0
};
