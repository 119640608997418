import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import Slider from 'react-slick';

import Button from '../../../components/jp-revamp/button';
import useI18n from '../../../hooks/use-i18n';
import { obt3EnabledMarkets } from '../../../links.config';

import '../../../styles/revamp/treatment-process.scss';

const TreatmentProcess = (props) => {
  const { utmParams } = props;

  const i18n = useI18n();
  const codes = i18n.activeLocale.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  const lang = codes[1] || 'en';
  const treatmentSection = i18n.t('new_landing_page').treatment_process_section;
  const contact = i18n.t('contact');

  const [activeSlide, setActiveSlide] = useState(0);

  const processSliderRef = useRef(null);
  const stepsSliderRef = useRef(null);

  const onStepNumberClick = (index) => {
    processSliderRef.current.slickGoTo(index);
  };

  const treatmentSteps = {
    step1: treatmentSection.step1_content,
    step2: treatmentSection.step2_content,
    step3: treatmentSection.step3_content,
    step4: treatmentSection.step4_content,
    step5: treatmentSection.step5_content,
    step6: treatmentSection.step6_content,
    step7: treatmentSection.step7_content
  };

  const calculateTransform = (current, next, defaultValue, translationValue) => {
    const innerSliderListStyle = processSliderRef.current.innerSlider.list.style;
    if (current === next) {
      innerSliderListStyle.transform = `translate3d(${defaultValue}px, 0, 0)`;
      innerSliderListStyle.transition = 'transform 500ms ease';
      return;
    }
    const isSwipeRight = next > current;
    const translateValue = isSwipeRight ? defaultValue + translationValue : defaultValue - translationValue;
    innerSliderListStyle.transform = `translate3d(${translateValue}px, 0, 0)`;
  };

  const treatmentProcessSettings = {
    accessibility: false,
    arrows: false,
    asNavFor: stepsSliderRef.current,
    autoplay: false,
    beforeChange: (current, next) => {
      calculateTransform(current, next, 0, 40);
      setActiveSlide(Math.ceil(next));
    },
    centerMode: true,
    infinite: false,
    initialSlide: 0,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    variableWidth: true,
    swipeToSlide: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          variableWidth: true,
          centerPadding: '20px',
          beforeChange: (current, next) => {
            calculateTransform(current, next, 18, 10);
            setActiveSlide(Math.ceil(next));
          }
        }
      }
    ]
  };

  const treatmentStepsSettings = {
    accessibility: false,
    arrows: false,
    autoplay: false,
    centerMode: true,
    infinite: false,
    initialSlide: 0,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    swipe: false,
    variableWidth: true
  };

  return (
    <section className="treatment-process-section" id="treatment-process">
      <h2
        data-key="new_landing_page::treatment_process_section::heading"
        data-lokalise=""
      >
        {treatmentSection.heading}
      </h2>
      <div className="treatment-step-nos">
        {Object.keys(treatmentSteps).map((step, index) => (
          <React.Fragment key={`treatment-no-${step}`}>
            <div
              className={`step-no ${activeSlide === index ? 'active' : ''}`}
              role="presentation"
              onClick={() => onStepNumberClick(index)}
            >
              <p>{`0${index + 1}`}</p>
            </div>
            <div className="step-divider" />
          </React.Fragment>
        ))}
      </div>
      <Slider {...treatmentStepsSettings} ref={stepsSliderRef} className="treatment-steps-slider">
        {Object.keys(treatmentSteps).map((step, index) => (
          <React.Fragment key={`treatment-no-${step}`}>
            <div
              className={`step-no ${activeSlide === index ? 'active' : ''}`}
              role="presentation"
              onClick={() => onStepNumberClick(index)}
            >
              <p>{`0${index + 1}`}</p>
            </div>
            <div className="step-divider" />
          </React.Fragment>
        ))}
      </Slider>
      <p
        className="total-steps"
        data-key="new_landing_page::treatment_process_section::out_of_total_steps"
        data-lokalise=""
      >
        {treatmentSection.out_of_total_steps}
      </p>
      <Slider
        {...treatmentProcessSettings}
        ref={processSliderRef}
        className="treatment-process-slider"
      >
        {Object.keys(treatmentSteps).map((step, index) => (
          <div
            className="treatment-card"
            key={`treatment-card-${step}`}
            role="presentation"
            onClick={() => onStepNumberClick(index)}
          >
            <div className="card-content">
              <div className="card-content-top">
                <p className="step-number">{`0${index + 1}`}</p>
                <p
                  className="card-text"
                  data-key={`new_landing_page::treatment_process_section::${step}_content`}
                  data-lokalise=""
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: treatmentSteps[step] }}
                />
              </div>
              {step === 'step1' && (
              <a
                href={`${obt3EnabledMarkets.includes(country) ? process.env.NEXT_PUBLIC_OBT3_LINK
                  : process.env.NEXT_PUBLIC_OBT_LINK}${country}-${lang}/hello-screen/${utmParams}`}
                className="smile-assessment-btn"
                data-key="new_landing_page::treatment_process_section::free_assessment_button"
                data-lokalise=""
              >
                {treatmentSection.free_assessment_button}
              </a>)}
            </div>
            <div className="card-img-container">
              <img
                src={`/img/jp-home-page/treatment-steps/treatment-${step}.png`}
                alt={`Treatment Step - ${index + 1}`}
                data-gumlet="false"
                draggable="false"
              />
              <svg
                className="svg-overlay"
                xmlns="http://www.w3.org/2000/svg"
                width="88"
                height="88"
                viewBox="0 0 88 88"
                fill="none"
              >
                <g>
                  <circle cx="44" cy="44" r="44" fill="#282829" />
                </g>
              </svg>
            </div>
          </div>
        ))}
      </Slider>
      <Button href={contact[country].line_link} className="line-button" externalLink={true} target="_blank">
        <>
          <img src="/img/jp-home-page/icons/line.svg" alt="line" data-gumlet="false" />
          <span
            data-key="new_landing_page::treatment_process_section::line_button"
            data-lokalise=""
          >
            {treatmentSection.line_button}
          </span>
        </>
      </Button>
    </section>
  );
};

export default TreatmentProcess;

TreatmentProcess.propTypes = {
  utmParams: PropTypes.string
};
TreatmentProcess.defaultProps = {
  utmParams: ''
};
