import React, { useRef, useState, useEffect } from 'react';

import debounce from 'debounce';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import useI18n from '../../../hooks/use-i18n';
import { obt3EnabledMarkets } from '../../../links.config';
import { paymentMethodMap } from '../../../utils/aligners.config';

import '../../../styles/revamp/pricing-section.scss';

const PricingSection = (props) => {
  const { utmParams } = props;

  const i18n = useI18n();
  const paymentSlider = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [maxHeight, setMaxHeight] = useState({
    alignerPrice: 64,
    installmentPrice: 0
  });

  const codes = i18n.activeLocale.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  const lang = codes[1] || 'en';
  const pricingTranslations = i18n.t('new_landing_page').pricing_section;

  const pricingSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveSlide(next),
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };

  const setCardHeight = (className) => {
    let contentHeight = 0;
    const cards = document.querySelectorAll(className);
    cards.forEach((content) => {
      contentHeight = Math.max(contentHeight, content.offsetHeight);
    });
    return contentHeight;
  };

  const onResize = debounce(() => {
    setMaxHeight({
      alignerPrice: 64,
      installmentPrice: 0
    });
    setTimeout(() => {
      setMaxHeight({ installmentPrice: setCardHeight('.installment-pricing'),
        alignerPrice: setCardHeight('.aligner-card-price') });
    }, 10);
  }, 300, false);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <section id="pricing">
      <div className="pricing-section">
        <div className="pricing-header">
          <h2
            data-key="new_landing_page::pricing_section::heading"
            data-lokalise=""
          >
            {pricingTranslations.heading}
          </h2>
          <p
            data-key="new_landing_page::pricing_section::sub_heading"
            data-lokalise=""
          >
            {pricingTranslations.sub_heading}
          </p>
        </div>
        <div className="payment-modes">
          <span
            role="presentation"
            onClick={() => { paymentSlider.current.slickPrev(); }}
            className={activeSlide === 0 ? 'active' : ''}
            data-key="new_landing_page::pricing_section::aligner_card1::aligner_name"
            data-lokalise=""
          >
            {pricingTranslations.aligner_card1.aligner_name}
          </span>
          <span
            role="presentation"
            onClick={() => { paymentSlider.current.slickNext(); }}
            className={activeSlide === 1 ? 'active' : ''}
            data-key="new_landing_page::pricing_section::aligner_card2::aligner_name"
            data-lokalise=""
          >
            {pricingTranslations.aligner_card2.aligner_name}
          </span>
        </div>
        <Slider {...pricingSliderSettings} className="paycards-container" ref={paymentSlider}>
          <div className="paycard">
            <div className="paycard-header">
              <h1
                data-key="new_landing_page::pricing_section::aligner_card1::heading"
                data-lokalise=""
              >
                {pricingTranslations.aligner_card1.heading}
              </h1>
            </div>
            <div className="paycard-body">
              <h1
                data-key="new_landing_page::pricing_section::aligner_card1::aligner_name"
                data-lokalise=""
              >
                {pricingTranslations.aligner_card1.aligner_name}
              </h1>
              <div
                className="aligner-card-price"
                style={{ minHeight: `${maxHeight.alignerPrice}px` }}
              >
                <div className="price-content">
                  <span
                    data-key="new_landing_page::pricing_section::aligner_card1::price"
                    data-lokalise=""
                  >
                    {pricingTranslations.aligner_card1.price}
                  </span>
                </div>
              </div>
              <div className="pricing-divider" />
              <p
                className="aligner-pricing installment-pricing"
                data-key="new_landing_page::pricing_section::aligner_card1::installment_price"
                data-lokalise=""
                style={{ minHeight: `${maxHeight.installmentPrice}px` }}
              >
                {pricingTranslations.aligner_card1.installment_price}
              </p>
              <div className="pricing-divider-secondary" />
              <p
                className="aligner-pricing"
                data-key="new_landing_page::pricing_section::aligner_card1::description"
                data-lokalise=""
              >
                {pricingTranslations.aligner_card1.description}
              </p>
            </div>
          </div>
          <div className="paycard paycard-two">
            <div className="paycard-header">
              <h1
                data-key="new_landing_page::pricing_section::aligner_card2::heading"
                data-lokalise=""
              >
                {pricingTranslations.aligner_card2.heading}
              </h1>
            </div>
            <div className="paycard-body">
              <h1
                data-key="new_landing_page::pricing_section::aligner_card2::aligner_name"
                data-lokalise=""
              >
                {pricingTranslations.aligner_card2.aligner_name}
              </h1>
              <div
                className="aligner-card-price"
                style={{ minHeight: `${maxHeight.alignerPrice}px` }}
              >
                <div className="price-content price-content-two">
                  <span
                    data-key="new_landing_page::pricing_section::aligner_card2::price"
                    data-lokalise=""
                  >
                    {pricingTranslations.aligner_card2.price}
                  </span>
                  <br />
                  <span
                    data-key="new_landing_page::pricing_section::aligner_card2::or"
                    data-lokalise=""
                  >
                    {pricingTranslations.aligner_card2.or}
                    &nbsp;
                  </span>
                  <span
                    data-key="new_landing_page::pricing_section::aligner_card2::price2"
                    data-lokalise=""
                  >
                    {pricingTranslations.aligner_card2.price2}
                  </span>
                </div>
              </div>
              <div className="pricing-divider" />
              <p
                className="aligner-pricing installment-pricing"
                data-key="new_landing_page::pricing_section::aligner_card2::installment_price"
                data-lokalise=""
                style={{ minHeight: `${maxHeight.installmentPrice}px` }}
              >
                {pricingTranslations.aligner_card2.installment_price}
              </p>
              <div className="pricing-divider-secondary" />
              <p
                className="aligner-pricing"
                data-key="new_landing_page::pricing_section::aligner_card2::description"
                data-lokalise=""
              >
                {pricingTranslations.aligner_card2.description}
              </p>
            </div>
          </div>
        </Slider>
        <p
          className="pricing-terms"
          data-key="new_landing_page::pricing_section::pricing_terms::pricing_terms_one"
          data-lokalise=""
        >
          ※
          {pricingTranslations.pricing_terms.pricing_terms_one}
        </p>
        <p
          className="pricing-terms"
          data-key="new_landing_page::pricing_section::pricing_terms::pricing_terms_two"
          data-lokalise=""
        >
          ※
          {pricingTranslations.pricing_terms.pricing_terms_two}
        </p>
        <p
          className="pricing-terms"
          data-key="new_landing_page::pricing_section::pricing_terms::pricing_terms_three"
          data-lokalise=""
        >
          ※
          {pricingTranslations.pricing_terms.pricing_terms_three}
        </p>
        <div className="payment-method-slider">
          {paymentMethodMap.jp.map((item, index) => (
            <div className="slider-img-wrapper" key={`${index + 1}+1`}>
              <img src={`/img/updated/homepage/payyourway/${item}.png`} alt={item} data-gumlet="false" />
            </div>
          ))}
        </div>
        <div className="free-assessment-wrapper">
          <a
            href={`${obt3EnabledMarkets.includes(country) ? process.env.NEXT_PUBLIC_OBT3_LINK
              : process.env.NEXT_PUBLIC_OBT_LINK}${country}-${lang}/hello-screen/${utmParams}`}
            data-key="new_landing_page::pricing_section::smile_assessment_button"
            data-lokalise=""
          >
            {pricingTranslations.smile_assessment_button}
          </a>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;

PricingSection.propTypes = {
  utmParams: PropTypes.string
};
PricingSection.defaultProps = {
  utmParams: ''
};
