import React from 'react';

import PropTypes from 'prop-types';

import '../../styles/revamp/smile-journey-card.scss';

function SmileJourneyCard(props) {
  const { cardDetails, step } = props;

  return (
    <div className="smile-journey-card">
      <div
        className="card-image-container"
        // data-bg={`/img/jp-home-page/smile-journey/${step}.jpg`}
        // data-gmlazy="false"
        data-gumlet="false"
        style={{ backgroundImage: `url(/img/jp-home-page/smile-journey/${step}.webp)` }}
      />
      <p
        className="card-title"
        data-key={`new_landing_page::smile_journey_section::smile_journey_cards::${step}_card::title`}
        data-lokalise=""
      >
        {cardDetails.title}
      </p>
      <p
        className="card-content"
        data-key={`new_landing_page::smile_journey_section::smile_journey_cards::${step}_card::content`}
        data-lokalise=""
      >
        {cardDetails.content}
      </p>
    </div>
  );
}

SmileJourneyCard.propTypes = {
  cardDetails: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string
  }),
  step: PropTypes.string
};

SmileJourneyCard.defaultProps = {
  cardDetails: {},
  step: ''
};

export default SmileJourneyCard;
