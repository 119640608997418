/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Collapse as BCollapse } from 'reactstrap';

import { trackPaymentEvent } from '../../helpers/payments';
import { referralFaqExpandedEvent } from '../../helpers/referral-segment';
import { infoOpenedEvent, faqExpandedEvent, alignerStoryPageFaqExpanded } from '../../helpers/segment';
import useI18n from '../../hooks/use-i18n';
import { countryMap } from '../../language.config';

import '../../styles/collapse.scss';

export default function Collapse (props) {
  const { title, content, Lkey, topic, context, type, itemNumber, isCollapseOpen, setIsCollapseOpen } = props;
  const [isOpen, toggleIsOpen] = useState(false);
  const i18n = useI18n();
  const codes = i18n.activeLocale.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';

  // CLEAN CODE use constants
  useEffect(() => {
    if (isOpen) {
      if (type === 'Consultation') {
        trackPaymentEvent('[PW] Offer Details Viewed', { dropdown_selected: title });
      } else if (type === 'Referrals') referralFaqExpandedEvent(itemNumber, title);
      else if (context) infoOpenedEvent(title, context, countryMap[country]);
      else faqExpandedEvent(title, topic, countryMap[country]);
    } else {
      setIsCollapseOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isCollapseOpen) {
      toggleIsOpen(true);
    }
  }, [isCollapseOpen]);

  return (
    <div className={`${isOpen ? 'active' : ''} collapse-wrapper`}>
      <div
        className="collapse-header"
        role="presentation"
        onClick={() => {
          toggleIsOpen(!isOpen);
          alignerStoryPageFaqExpanded(countryMap[country], Lkey);
        }}
      >
        <h3
          data-lokalise=""
          data-key={`${Lkey}::question`}
        >
          {title}
        </h3>
        <span className="plus" />
      </div>
      <BCollapse isOpen={isOpen}>
        <div
          className="collapse-body"
          data-lokalise=""
          data-key={`${Lkey}::answer`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </BCollapse>
    </div>
  );
}

Collapse.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  Lkey: PropTypes.string,
  context: PropTypes.string,
  topic: PropTypes.string,
  type: PropTypes.string,
  itemNumber: PropTypes.string,
  isCollapseOpen: PropTypes.bool,
  setIsCollapseOpen: PropTypes.func
};

Collapse.defaultProps = {
  title: '',
  content: '',
  Lkey: '',
  context: '',
  topic: '',
  type: '',
  itemNumber: '',
  isCollapseOpen: false,
  setIsCollapseOpen: () => {}
};
