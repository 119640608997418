import enc from 'crypto-js';
import sha256 from 'crypto-js/sha256';

export const referralFaqExpandedEvent = (itemNumber, title) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics.track('FAQ opened', {
      tab: `FAQ ${itemNumber}`,
      title
    });
  }
};

export const pageviewEvent = (pageName, data = {}) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.page(pageName, data);
  }
};

export const referralIdentify = (name, email, phoneNumber) => {
  const userId = sha256(email).toString(enc.Hex);
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.identify(userId, {
      name,
      email,
      phone_number: phoneNumber
    });
  }
};

export const startNowClickEvent = () => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.track('Recipient LP Start Now Clicked');
  }
};

export const getReferrerIdEvent = (referralId) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.track('Referrer ID', { id: referralId });
  }
};

export const checkMobDesk = (device) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.track('Device viewed', { device });
  }
};

export const getMobileButtonDetails = () => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.track('Recipient LP Start Free Assessment Clicked');
  }
};

export const getHeadingMobileButtonDetails = () => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.track('Recipient LP Learn more CTA');
  }
};

export const userStoryTile = (tileNumber) => {
  if (process.env.NEXT_PUBLIC_SEGMENT_ID) {
    window.analytics?.track('Recipient LP User Story Tile', {
      tile_number: tileNumber
    });
  }
};
