/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useRef, useState } from 'react';

import Slider from 'react-slick';

import BeforeAfterContentImage from '../../../components/jp-revamp/before-after-content-image';
import useI18n from '../../../hooks/use-i18n';

import '../../../styles/jp-revamp/before-after-slider-JP.scss';

const BeforeAfterSliderJP = () => {
  const i18n = useI18n();
  const toothComparisonSliderRef = useRef(null);
  const [isCompareSliderActive, setIsCompareSliderActive] = useState(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const beforeAfterSection = i18n.t('new_landing_page.before_and_after_image_section');

  const beforeAfterSliderConfig = {
    accessibility: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    touchThreshold: 10,
    centerPadding: '32px',
    swipe: false,
    tabIndex: '0',
    beforeChange: (current, next) => {
      setActiveSlideIndex(next);
    },
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          swipe: false,
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1023,
        settings: {
          swipe: isCompareSliderActive,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          swipe: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          swipe: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const imageData = ['anterior-crossbite', 'sherri', 'crowding-anterior',
    'overbite', 'high-canines', 'spacing', 'premolar-crossbite', 'rotation-crowding'];

  return (
    <section className="animated-banner" id="before-and-after">
      <h2
        data-lokalise=""
        data-key="new_landing_page::before_and_after_image_section::main_heading"
      >
        {beforeAfterSection.main_heading}
      </h2>
      <p
        data-lokalise=""
        data-key="new_landing_page::before_and_after_image_section::sub_heading"
      >
        {beforeAfterSection.sub_heading}
      </p>
      <div className="before-after-image-slider-wrapper">
        <img
          src="/img/jp-home-page/icons/arrow.svg"
          alt="arrow"
          className={`left-arrow-button ${activeSlideIndex <= 0 ? 'arrow-disabled' : ''}`}
          data-gumlet="false"
          role="presentation"
          onClick={() => toothComparisonSliderRef.current.slickPrev()}
        />
        <Slider {...beforeAfterSliderConfig} ref={toothComparisonSliderRef}>
          {imageData.map((data) => (
            <div className="before-after-slide-wrapper" key={data}>
              <BeforeAfterContentImage
                beforeImageUrl={`/img/jp-home-page/before-after-images/${data}-before.png`}
                afterImageUrl={`/img/jp-home-page/before-after-images/${data}-after.png`}
                position={25}
                onlyHandleDraggable={true}
                setIsCompareSliderActive={setIsCompareSliderActive}
                boundsPadding="5"
              />
            </div>
          ))}
        </Slider>
        <img
          src="/img/jp-home-page/icons/arrow.svg"
          alt="arrow"
          className={`right-arrow-button ${activeSlideIndex === imageData.length - 1 ? 'arrow-disabled' : ''}`}
          data-gumlet="false"
          role="presentation"
          onClick={() => toothComparisonSliderRef.current.slickNext()}
        />
      </div>
    </section>
  );
};

export default BeforeAfterSliderJP;
