import React from 'react';

import Collapse from '../../../components/includes/collapse';
import useI18n from '../../../hooks/use-i18n';

import '../../../styles/jp-revamp/faqs.scss';

const FaqSection = () => {
  const i18n = useI18n();

  const faq = i18n.t('new_landing_page.faq_section');

  return (
    <section className="jp-faq-section-wrapper" id="faq">
      <div className="new-faq faq-section">
        <h2 data-lokalise="" data-key="new_landing_page::faq_section::header">{faq.header}</h2>
        <div className="">
          {faq.data && faq.data.length > 0 && faq.data.map((row, iKey) => (
            row.question && row.question !== 'nil' && (
            <div className="faq-collapse-wrapper" key={row.question}>
              <Collapse
                title={row.question}
                Lkey={`new_landing_page::faq_section::data::${iKey}`}
                content={row.answer}
                topic={faq.title}
              />
            </div>
            )
          ))}
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
