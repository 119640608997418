/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useInView } from 'react-intersection-observer';
import Slider from 'react-slick';
import { Collapse as BCollapse } from 'reactstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import { fetchProductLists } from '../../apis/products';
import useDrag from '../../components/revamp/useDrag';
import { viewItemList } from '../../helpers/analytics';
import { reviewSectionExpanded, guideCardClicked,
  productCardClicked, productCardDiscoverClicked,
  promoBannerClicked, shopAllClicked, promoBannerDiscoverClicked } from '../../helpers/segment';
import useI18n from '../../hooks/use-i18n';
import { countryMap } from '../../language.config';
import { sonicMarkets, freshMarkets, waterflosserMarkets,
  reviewsIoMarkets, embedCodes, cartDisabledMarkets,
  sonicGoMarkets, whiteningMarkets, warrantyEnabledMarkets,
  obt3EnabledMarkets } from '../../links.config';
import { config } from '../../recoil/atoms/config';
import { productsReviews } from '../../recoil/atoms/productsReviews';
import { productListsData } from '../../recoil/selectors/productLists';
import END_POINTS, { PAGES_URL } from '../../utils/shopify-endpoints';
import { homePageFavSlider } from '../../utils/slider.config';
import { getProductSkusByPage,
  initializeStoryReviews, useUtmParam as useUtmData,
  analyticProductDataConfig2, igFeeds, embedRatings,
  removeEmbeds, SHOPIFY_URLS, DEFAULT_HOME_BANNERS } from '../../utils/utils';

import '../../styles/home-revamp.scss';

const UserRating = dynamic(() => import('../../components/includes/user-rating'), { ssr: false });
const SliderItem4 = dynamic(() => import('../../components/listing/slider-item-4'), { ssr: false });
const BannerCard = dynamic(() => import('../../components/includes/home-section-banner'), { ssr: false });

const HomePageRevamp = () => {
  const i18n = useI18n();
  const COUNTRY_LANGUAGE = i18n.activeLocale;
  const codes = COUNTRY_LANGUAGE.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  const lang = codes[1] || 'en';
  const homePageData = i18n.t('home_page_new');
  const reviewTranslations = i18n.t('review_widget_story');
  const router = useRouter();
  const query = router && router.query;

  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [utmParams, setUtmParams] = useState('');
  const [bannerMarketCount, setBannerMarketCount] = useState(0);
  const [bannerMarkets, setBannerMarkets] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [reviewsRef, reviewsInView] = useInView({ threshold: 0.1, triggerOnce: true });

  const [productListData, setProductListData] = useRecoilState(productListsData);
  const productDataReviews = useRecoilValue(productsReviews);
  const configData = useRecoilValue(config);
  const { dragStart, dragStop, dragMove } = useDrag();
  const handleDrag = ({ scrollContainer }) => (ev) => dragMove(ev, (posDiff) => {
    if (scrollContainer.current) {
      // eslint-disable-next-line no-param-reassign
      scrollContainer.current.scrollLeft += posDiff;
    }
  });

  useEffect(() => {
    const allMarkets = { whitening: whiteningMarkets,
      sonicgo: sonicGoMarkets,
      waterflosser: waterflosserMarkets,
      sonic: sonicMarkets,
      fresh: freshMarkets };
    const bannerMarketsArr = Object.keys(allMarkets).filter((market) => (allMarkets[market].includes(country)));
    setBannerMarkets(bannerMarketsArr);
    setBannerMarketCount(bannerMarketsArr.length);
  }, [country]);

  function valueChange() {
    setIsMobile(window.innerWidth < 672);
    setWindowWidth(window.innerWidth);
  }

  const onPageLoadEvents = () => {
    valueChange();
  };

  useEffect(() => {
    valueChange();
    window.addEventListener('load', onPageLoadEvents);
    window.addEventListener('resize', () => {
      if (windowWidth > 672) {
        setTimeout(() => {
          valueChange();
        }, 500);
      }
    });

    return () => {
      window.removeEventListener('load', onPageLoadEvents);
      removeEmbeds(document, 'EmbedSocialHashtagScript');
    };
  }, []);

  useEffect(() => {
    if (Object.keys(productDataReviews).length > 0) {
      getProductLists();
      if (reviewsIoMarkets.includes(country)) {
        const zenSonicSkus = getProductSkusByPage(['TOOTHBRUSH', 'BRUSHHEAD'], productDataReviews);
        initializeStoryReviews('reviewsio-carousel-widget-sonic', zenSonicSkus, lang, reviewTranslations);
      }
    }
  }, [productDataReviews]);

  useEffect(() => {
    setTimeout(() => {
      setUtmParams(useUtmData('Homepage'));
    }, 50);
  }, [query]);

  useEffect(() => {
    igFeeds(document, 'script', 'EmbedSocialHashtagScript');
    embedRatings(document, 'script', 'EmbedSocialReviewsScript');
    return () => {
      removeEmbeds(document, 'EmbedSocialHashtagScript', 'embedsocial-hashtag');
      removeEmbeds(document, 'EmbedSocialReviewsScript', 'embedsocial-reviews');
    };
  }, [country]);

  const getProductLists = async () => {
    if (!productListData.HomePageFavourites) {
      const recommendations = await fetchProductLists(['HomePageFavourites']);
      if (recommendations) setProductListData({ HomePageFavourites: recommendations });
    }
  };

  useEffect(() => {
    if (productListData?.HomePageFavourites && productListData?.HomePageFavourites.length) {
      const data = analyticProductDataConfig2(productListData?.HomePageFavourites);
      viewItemList(data.productList, data.currencyCode, 'home_page_recommendation');
    }
  }, [productListData.HomePageFavourites]);

  const onProductDiscoverClick = (evt, product, productCategory) => {
    evt.stopPropagation();
    productCardDiscoverClicked(countryMap[country], product, productCategory);
  };

  const bannerDesktop = configData?.banners?.homepageDesktop
  && configData.banners.homepageDesktop.length > 0 ? configData.banners.homepageDesktop[0]
    : {
      bannerUrl: DEFAULT_HOME_BANNERS[country].desktop
    };
  const bannerMobile = configData?.banners?.homepageMobile
  && configData.banners.homepageMobile.length > 0 ? configData.banners.homepageMobile[0] : {
      bannerUrl: DEFAULT_HOME_BANNERS[country].mobile
    };
  return (
    <>
      <main className="homenew-layout zenyum-product-page">
        {configData?.loaded ? (
          <section
            data-gumlet="false"
            className={`homenew-layout__banner mobile-${bannerMobile.textTheme} desktop-${bannerDesktop.textTheme}`}
            // data-bg={isMobile ? gumletAWSClean(bannerMobile?.bannerUrl) : gumletAWSClean(bannerDesktop?.bannerUrl)}
            style={{ backgroundImage: `url(${isMobile ? bannerMobile?.bannerUrl : bannerDesktop?.bannerUrl})` }}
          >
            <div>
              <h1>
                {isMobile ? bannerMobile.header : bannerDesktop.header}
              </h1>
              <p
                dangerouslySetInnerHTML={{ __html: isMobile ? bannerMobile.body : bannerDesktop.body }}
              />
              <div className="homenew-buttonbox">
                {bannerDesktop.hyperLink1 && (
                <a
                  href={`${isMobile ? bannerMobile.hyperLink1
                    || bannerDesktop.hyperLink1
                    : bannerDesktop.hyperLink1}${bannerDesktop.hyperLink1.includes('obt')
                    ? utmParams : ''}`}
                  className="web-btn"
                  onClick={() => { promoBannerClicked(countryMap[country], bannerDesktop.cta1, bannerDesktop.cta1); }}
                >
                  {isMobile ? bannerMobile.cta1 || bannerDesktop.cta1 : bannerDesktop.cta1}
                </a>
                )}
                {bannerDesktop.hyperLink2 && (
                <a
                  className="learn-more"
                  href={`${isMobile ? bannerMobile.hyperLink2
                    || bannerDesktop.hyperLink2
                    : bannerDesktop.hyperLink2}${bannerDesktop.hyperLink2.includes('obt')
                    ? utmParams : ''}`}
                  onClick={() => {
                    promoBannerDiscoverClicked(countryMap[country], bannerDesktop.cta2, bannerDesktop.cta2);
                  }}
                >
                  {isMobile ? bannerMobile.cta2 || bannerDesktop.cta2 : bannerDesktop.cta2}
                </a>
                )}
              </div>
            </div>
          </section>
        )
          : (
            <div className="banner-skeleton-loading">
              <div className="banner-content" />
              <div className="banner-sub-content" />
            </div>)}
        <section className="homenew-layout__content overflow-hidden">
          <div className="cds--grid" onMouseLeave={dragStop}>
            <h2>{homePageData.banner_small_heading}</h2>
            <ScrollMenu
              onMouseDown={() => dragStart}
              onMouseUp={() => dragStop}
              onMouseMove={handleDrag}
            >
              <div className="slide-wrapper">
                <div className="content-box-small">
                  <Link href="/[lng]/invisible-braces" as={`/${i18n.activeLocale}/invisible-braces`}>
                    <a
                      data-gumlet="true"
                      className="image-box"
                      data-bg="../img/home-new/home-subbanner-img1.jpg"
                      role="presentation"
                      onClick={() => { guideCardClicked(countryMap[country], 'Aligners'); }}
                    >
                      <p
                        data-lokalise=""
                        data-key="home_page_new::banner_small_text1"
                      >
                        {homePageData.banner_small_text1}
                      </p>
                    </a>
                  </Link>
                </div>
              </div>
              {sonicMarkets.includes(country) && (
              <div className="slide-wrapper">
                <div className="content-box-small">
                  <a
                    data-gumlet="true"
                    className="image-box"
                    href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PAGES_URL}${END_POINTS.SHOPALL}`}
                    data-bg="../img/home-new/home-subbanner-img2.jpg"
                    onClick={() => { guideCardClicked(countryMap[country], 'Shop All'); }}
                    referrerPolicy="no-referrer"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <p
                      data-lokalise=""
                      data-key="home_page_new::banner_small_text2"
                    >
                      {homePageData.banner_small_text2}
                    </p>
                  </a>
                </div>
              </div>
              )}
              <div className="slide-wrapper">
                <div className="content-box-small">
                  <Link href="/[lng]/about" as={`/${i18n.activeLocale}/about`}>
                    <a
                      data-gumlet="true"
                      className="image-box"
                      data-bg="../img/home-new/home-subbanner-img3.jpg"
                      role="presentation"
                      onClick={() => { guideCardClicked(countryMap[country], 'About'); }}
                    >
                      <p
                        data-lokalise=""
                        data-key="home_page_new::banner_small_text3"
                      >
                        {homePageData.banner_small_text3}
                      </p>
                    </a>
                  </Link>
                </div>
              </div>
              {warrantyEnabledMarkets.includes(country) && (
              <div className="slide-wrapper">
                <div className="content-box-small">
                  <Link href="/[lng]/warranty-activation" as={`/${i18n.activeLocale}/warranty-activation`}>
                    <a
                      className="image-box"
                      data-bg="../img/home-new/home-subbanner-img4.jpg"
                      role="presentation"
                      onClick={() => { guideCardClicked(countryMap[country], 'Activate Warranty'); }}
                    >
                      <p
                        data-lokalise=""
                        data-key="home_page_new::banner_small_text4"
                      >
                        {homePageData.banner_small_text4}
                      </p>
                    </a>
                  </Link>
                </div>
              </div>)}
            </ScrollMenu>
          </div>
        </section>
        <div className="homenew-layout__bannersection">
          <section className="homenew-layout__content content-margin-less">
            <div className="cds--grid">
              <div
                data-gumlet="true"
                className="image-box content-box-large"
                data-bg={`../img/home-new/home-aligner-banner${isMobile ? '_mobile' : ''}.jpg`}
                              // style={{
                              //   backgroundImage:
                              //     `url('../img/home-new/home-aligner-banner${isMobile ? '_mobile' : ''}.jpg')`
                              // }}
                role="presentation"
                onClick={() => { productCardClicked(countryMap[country], 'Invisible Braces', 'Invisible Braces'); }}
              >
                <div>
                  <h3
                    data-lokalise=""
                    data-key="home_page_new::home_section::aligner_heading"
                    dangerouslySetInnerHTML={{ __html: homePageData.home_section.aligner_heading }}
                  />
                  <p
                    data-lokalise=""
                    data-key="home_page_new::home_section::aligner_detail"
                    dangerouslySetInnerHTML={{ __html: homePageData.home_section.aligner_detail }}
                  />
                  <div className="homenew-buttonbox">
                    <a
                      className="web-btn"
                      data-lokalise=""
                      href={`${obt3EnabledMarkets.includes(country) ? process.env.NEXT_PUBLIC_OBT3_LINK
                        : process.env.NEXT_PUBLIC_OBT_LINK}${country}-${lang}/intro/${utmParams}`}
                      data-key="home_page_new::assessment_button"
                    >
                      {homePageData.assessment_button}
                    </a>
                    <Link href="/[lng]/invisible-braces" as={`/${i18n.activeLocale}/invisible-braces`}>
                      <a
                        className="learn-more"
                        data-lokalise=""
                        data-key="home_page_new::learn_more"
                        role="presentation"
                        onClick={(evt) => { onProductDiscoverClick(evt, 'Invisible Braces', 'Invisible Braces'); }}
                      >
                        {homePageData.learn_more}
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="homenew-layout__content content-margin-less">
            <div className="cds--grid">
              {bannerMarketCount % 2 === 1 && bannerMarketCount !== 0 && (
              <BannerCard
                size="large"
                product={bannerMarkets?.[0]}
              />
              )}
            </div>
          </section>
        </div>
        {!cartDisabledMarkets.includes(country) && productListData.HomePageFavourites && (
        <section className="homenew-layout__content overflow-hidden">
          <div className="cds--grid">
            <h2 className="display-inline-block">{homePageData.shop_the_fav}</h2>
            <a
              className="see-all"
              referrerPolicy="no-referrer"
              rel="noreferrer"
              href={`${SHOPIFY_URLS[COUNTRY_LANGUAGE]}${PAGES_URL}${END_POINTS.SHOPALL}`}
              onClick={() => shopAllClicked(countryMap[country], 'Shop Favourites')}
              target="_blank"
            >
              {homePageData.see_all}
            </a>
            <Slider {...homePageFavSlider} className="homenew-layout__slider">
              {productListData.HomePageFavourites && productListData.HomePageFavourites.map((product) => (
                <div className="slide-wrapper" key={product.id}>
                  <SliderItem4 data={product} />
                </div>
              ))}
            </Slider>
          </div>
        </section>
        )}

        <section className="homenew-layout__content">
          <div className="cds--grid">
            <div className="cds--row">
              {bannerMarkets.map((market, index) => (
                bannerMarketCount % 2 === 1 && index !== 0 && (
                <BannerCard
                  size="small"
                  product={market}
                  key={market}
                />
                )
              ))}
              {bannerMarkets.map((market) => (
                bannerMarketCount % 2 === 0 && (
                <BannerCard
                  size="small"
                  product={market}
                  key={market}
                />
                )
              ))}
            </div>
          </div>
        </section>

        {country !== 'th' && country !== 'jp' && reviewsIoMarkets.includes(country) && (
        <section className="homenew-layout__content">
          <div className="cds--grid">
            <h2
              className="text-align-center"
              data-lokalise=""
              data-key="home_page_new::home_ratings_heading"
              dangerouslySetInnerHTML={{ __html: homePageData.home_ratings_heading }}
            />
            <div className="user-ratings sonic-ratings" ref={reviewsRef}>
              <div className={`ratings-wrapper ${isReviewOpen ? 'activeOpen' : ''}`}>
                <UserRating
                  onClick={() => {
                    setIsReviewOpen(!isReviewOpen);
                    reviewSectionExpanded('Home', 'Zenyum Sonic', countryMap[country]);
                  }}
                  isActive={isReviewOpen}
                  dataKey="home_page.zenyum_sonic_reviews"
                  type={homePageData.zenyum_sonic_reviews}
                />
                <BCollapse isOpen={true}>
                  {!reviewsIoMarkets.includes(country) ? (
                    <div className="embedsocial-reviews" data-ref={embedCodes[country].sonic} />
                  ) : (<div id="reviewsio-carousel-widget-sonic" className="mt-5 mt-md-0" />)}
                </BCollapse>
              </div>
              {reviewsIoMarkets.includes(country) && (
              <a
                href="https://www.reviews.io/company-reviews/store/zenyum"
                className="web-btn"
                data-lokalise=""
                data-key="home_page_new::reviews_button"
                rel="noreferrer"
                target="_blank"
              >
                {homePageData.reviews_button}
              </a>
              )}
            </div>
          </div>
        </section>
        )}
        {embedCodes[country].instagram && (country === 'jp' || country === 'id') && (
        <section className="cds--grid homenew-layout__content">
          <div className="instafeed-section width-full">
            {country === 'id' && (
            <h2
              className="mb-4 mb-sm-3"
              data-lokalise=""
              data-key="home_page_new::ig_header"
            >
              {homePageData.ig_header}
            </h2>
            )}
            <div className="embedsocial-hashtag" data-ref={embedCodes[country].instagram} />
          </div>
        </section>
        )}
      </main>

    </>
  );
};

export default HomePageRevamp;
