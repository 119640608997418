
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[lng]",
      function () {
        return require("private-next-pages/[lng]/index.js");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[lng]"])
      });
    }
  