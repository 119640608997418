export const sliderSettings = {
  arrows: false,
  dots: true,
  accessibility: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};



export const recommendSlider = {
  arrows: true,
  accessibility: true,
  infinite: false,
  speed: 700,
  slidesToShow: 6,
  centerPadding: 20,
  slidesToScroll: 1,
  dots: false,
  swipe: true,
  touchThreshold: 30,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        swipe: true,
        arrows: false,
        slidesToShow: 2.4
      }
    },
    {
      breakpoint: 576,
      settings: {
        swipe: true,
        arrows: false,
        slidesToShow: 2.32
      }
    }
  ]
};

export const aboutPageSliderSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  variableWidth: true,
  autoplay: true,
  swipe: true,
  speed: 1000,
  autoplaySpeed: 4000,
  touchThreshold: 20,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 672,
      settings: {
        infinite: false,
        autoplay: false,
        swipe: false
      }
    }
  ]
};

export const sliderSettingsDentistPage1 = {
  accessibility: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.2,
        slidesToScroll: 1,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: '60px',
        centerMode: true,
        slidesToScroll: 1,
        infinite: false,
        dots: true
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        centerPadding: '40px',
        centerMode: true,
        slidesToScroll: 1,
        infinite: false,
        dots: true
      }
    }
  ]
};
export const sliderSettingsDentistPage2 = {
  arrows: true,
  accessibility: true,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 1,
  dots: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        arrows: true
      }
    }
  ]
};

export const dentistPageSlides = ['1.jpg',
  '2.jpg',
  '3.jpg',
  '4.jpg',
  '5.jpg',
  '6.jpg',
  '1.jpg',
  '2.jpg',
  '3.jpg',
  '4.jpg'];

export const homePageFavSlider = {
  accessibility: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: false,
  touchThreshold: 10,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        swipe: true,
        slidesToShow: 2.7
      }
    },
    {
      breakpoint: 768,
      settings: {
        swipe: true,
        slidesToShow: 2.4
      }
    },
    {
      breakpoint: 576,
      settings: {
        swipe: true,
        slidesToShow: 1.91
      }
    }
  ]
};

export const animatedBannerSlider = {
  accessibility: true,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 2,
  dots: true,
  touchThreshold: 10,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        swipe: true,
        slidesToShow: 4,
        slidesToScroll: 1

      }
    },
    {
      breakpoint: 1025,
      settings: {
        swipe: true,
        slidesToShow: 4,
        slidesToScroll: 4

      }
    },
    {
      breakpoint: 768,
      settings: {
        swipe: true,
        slidesToShow: 3,
        slidesToScroll: 3

      }
    },
    {
      breakpoint: 576,
      settings: {
        swipe: true,
        slidesToShow: 2,
        slidesToScroll: 2

      }
    }
  ]
};

export const beforeAfterSliderConfig = {
  accessibility: true,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  dots: true,
  touchThreshold: 10,
  centerPadding: '32px',
  swipe: false,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        swipe: false,
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1023,
      settings: {
        swipe: true,
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 767,
      settings: {
        swipe: true,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 576,
      settings: {
        swipe: true,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const HowZenyumWorksComparisonSlider = {
  arrows: true,
  centerMode: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2.26,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: 0,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
        infinite: true,
        dots: false,
        arrows: false
      }
    }
  ]
};
