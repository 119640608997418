import React from 'react';

import SliderCard from '../../../components/jp-revamp/slider-card';
import useI18n from '../../../hooks/use-i18n';

import '../../../styles/jp-revamp/why-details-section.scss';

const DetailsSection = () => {
  const i18n = useI18n();
  const detailsSection = i18n.t('new_landing_page.detailed_section');

  const sponsorImages = ['kpmg.png', 'nikkei.png', 'wwd.png', 'yahoo.png', 'news-pick.png'];

  const detailsSectionData = [{
    key: '1',
    imageUrl: '1',
    heading: detailsSection.details_card.card_one.heading,
    content: detailsSection.details_card.card_one.subsection,
    lokaliseKey: 'card_one'
  },
  {
    key: '2',
    imageUrl: '2',
    heading: detailsSection.details_card.card_two.heading,
    content: detailsSection.details_card.card_two.subsection,
    lokaliseKey: 'card_two'
  },
  {
    key: '3',
    imageUrl: '3',
    heading: detailsSection.details_card.card_three.heading,
    content: detailsSection.details_card.card_three.subsection,
    lokaliseKey: 'card_three'
  }];

  return (
    <section className="details-section-wrapper" id="why-zenyum-details">
      <h2
        data-lokalise=""
        data-key="new_landing_page::detailed_section::detailed_section_main_heading"
      >
        {detailsSection.detailed_section_main_heading}
      </h2>
      <div className="details-content-wrapper">
        {detailsSectionData.map((item) => (
          <SliderCard
            imageUrl={`/img/jp-home-page/tiles-images/why_zenyum_${item.imageUrl}.jpg`}
            contentHeading={item.heading}
            content={item.content}
            key={item.key}
            lokalizeKey={item.lokaliseKey}
          />
        ))}
      </div>
      <div className="sponsor-section">
        {sponsorImages.length && sponsorImages.map((image) => (
          <div className={`slide-wrapper ${image.split('.')[0]}`} key={image}>
            <img data-src={`/img/jp-home-page/sponsor-images/new/${image}`} alt={image.split('.')[0]} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default DetailsSection;
