/* eslint-disable react/no-danger */

import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';

import MainBanner from '../../components/jp-revamp/mainBanner';
import useI18n from '../../hooks/use-i18n';
import { config } from '../../recoil/atoms/config';
import { DEFAULT_HOME_BANNERS, useUtmParam as useUtmData } from '../../utils/utils';
import AnimatedToothBanner from '../jpRevamp/component/animatedToothBanner';
import BeforeAfterSliderJP from '../jpRevamp/component/beforeAfterSlider';
import ClinicSection from '../jpRevamp/component/clinicSection';
import CtaBanner2 from '../jpRevamp/component/ctaBanner2';
import DetailsSection from '../jpRevamp/component/detailsSection';
import FaqSection from '../jpRevamp/component/faqSection';
import PricingSection from '../jpRevamp/component/pricingSection';
import ReviewsSlider from '../jpRevamp/component/reviewsSlider';
import SmileJourney from '../jpRevamp/component/smileJourney';
import TreatmentProcess from '../jpRevamp/component/treatmentProcess';

import '../../styles/jp-revamp/jp-home-page.scss';

const JpHomePage = () => {
  const i18n = useI18n();
  const COUNTRY_LANGUAGE = i18n.activeLocale;
  const codes = COUNTRY_LANGUAGE.split('-');
  const country = codes.length === 2 ? codes[0] : 'sg';
  const router = useRouter();
  const query = router && router.query;

  const [utmParams, setUtmParams] = useState('');
  const [isNotification, toggleIsNotification] = useState(false);
  const configData = useRecoilValue(config);

  useEffect(() => {
    setTimeout(() => {
      setUtmParams(useUtmData('Homepage'));
    }, 50);
  }, [query]);

  useEffect(() => {
    toggleIsNotification(document.querySelector('.product-notify-bar'));
  }, [configData]);

  const bannerDesktop = configData?.banners?.homepageDesktop
  && configData.banners.homepageDesktop.length > 0 ? configData.banners.homepageDesktop[0] : {
      bannerUrl: DEFAULT_HOME_BANNERS[country].desktop
    };
  const bannerMobile = configData?.banners?.homepageMobile
  && configData.banners.homepageMobile.length > 0 ? configData.banners.homepageMobile[0] : {
      bannerUrl: DEFAULT_HOME_BANNERS[country].mobile
    };

  return (
    <>
      <main className={`homenew-layout-jp ${isNotification ? 'notification-enabled' : ''}`}>
        <MainBanner
          utmParams={utmParams}
          bannerDesktop={bannerDesktop}
          bannerMobile={bannerMobile}
          isLoaded={configData?.loaded}
        />
        <DetailsSection />
        <AnimatedToothBanner />
        <BeforeAfterSliderJP />
        <TreatmentProcess utmParams={utmParams} />
        <PricingSection utmParams={utmParams} />
        <SmileJourney />
        <ClinicSection />
        <CtaBanner2 utmParams={utmParams} />
        <FaqSection />
        <ReviewsSlider />
      </main>
    </>
  );
};

export default JpHomePage;
